@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

html {
  scroll-behavior: smooth;
  font-family: "Playfair Display", serif;
}

#me {
  font-family: "Hahmlet", serif !important;
  font-size: 18vh !important;
}

#port {
  font-family: "Hahmlet", serif !important;
  font-size: 18vh !important;
}

#small {
  font-family: "Hahmlet", serif !important;
  font-size: 15vh !important;
}

.subheading {
  font-family: "Hahmlet", serif !important;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 500px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

#images {
  height: 100vh !important;
  max-height: 100vh !important;
}
.TYtqb img {
  width: 80vh !important;
  max-height: 90vh !important;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  align-items: start;
  justify-items: center;
}
.imageGrid img {
  max-width: 100%;
}
.imageGrid img:nth-child(2) {
  grid-column: span 3;
  grid-row: span 3;
}

.imageGrid img:nth-child(6) {
  grid-column: span 4;
  grid-row: span 3;
}

.imageGrid img:nth-child(10) {
  grid-column: span 2;
  grid-row: span 3;
}

.imageGrid img:nth-child(14) {
  grid-column: span 3;
  grid-row: span 2;
}
.imageGrid img:nth-child(15) {
  grid-column: span 4;
  grid-row: span 3;
}

.imageGrid img:nth-child(19) {
  grid-column: span 3;
  grid-row: span 3;
}

.imageGrid img:nth-child(27) {
  grid-column: span 3;
  grid-row: span 3;
}

.imageGrid img:nth-child(32) {
  grid-column: span 3;
  grid-row: span 3;
}
.imageGrid img:nth-child(36) {
  grid-column: span 5;
  grid-row: span 3;
}

@media only screen and (max-width: 480px) {
  #me {
    font-family: "Hahmlet", serif !important;
    font-size: 6.5vh !important;
  }
  #port {
    font-family: "Hahmlet", serif !important;
    font-size: 9.5vh !important;
  }
  #header {
    font-family: "Hahmlet", serif !important;
    font-size: 7vh !important;
  }
  #small {
    font-family: "Hahmlet", serif !important;
    font-size: 5vh !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

html {
  font-family: "Montserrat";
  scroll-behavior: smooth;
}

.title {
  font-family: "Hahmlet", serif !important;
}

.navitems {
  font-family: "Playfair Display", serif !important;
}

.navitems h1 {
  font-family: "Playfair Display", serif;
}

.scroll-down {
  height: 50px;
  width: 30px;
  border: 2px solid black;
  position: absolute;
  left: 50%;
  bottom: 20px;
  border-radius: 50px;
  cursor: pointer;
}
.scroll-down::before,
.scroll-down::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid black;
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}
.scroll-down::before {
  top: 30%;
  animation-delay: 0.3s;
  /* animation: scroll-down 1s ease-in-out infinite; */
}

@keyframes scroll-down {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

#header {
  font-family: "Hahmlet", serif;
}

#title {
  font-family: "Hahmlet", serif;
  font-weight: bold;
}

#image {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  #header {
    font-family: "Hahmlet", serif !important;
    font-size: 12vh !important;
  }
}
